import { Controller } from "@hotwired/stimulus";

export class TrackFilterToggleController extends Controller {
  static targets = ["trackFilterTypeSelect", "track", "ticketTypes"];

  connect() {
    this.setVisibility();
  }

  setVisibility() {
    switch (this.trackFilterTypeSelectTarget.value) {
      case "matches_track":
        this.setTrackVisibility(true);
        this.setTicketTypeVisibility(false);
        break;
      case "matches_ticket_type":
        this.setTrackVisibility(false);
        this.setTicketTypeVisibility(true);
        break;
      default:
        this.setTrackVisibility.call(this, false);
        this.setTicketTypeVisibility.call(this, false);
    }
  }

  setTrackVisibility(visible) {
    this.trackTargets.forEach((el) => (el.hidden = !visible));
  }

  setTicketTypeVisibility(visible) {
    this.ticketTypesTargets.forEach((el) => (el.hidden = !visible));
  }
}
