import React, { useState } from "react";
import {
  AdminApiClient,
  AdminApiContext,
  gregAdminTheme,
  MicrositeEditor as BaseEditor,
} from "@getregistered/greg-editors";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ErrorHandler from "./ErrorHandler";

import railsFetch from "./railsFetch";

const mapSectionsToRailsParams = (sections, deletedSections) => {
  const railsSections = sections.map((section, index) => {
    const railsSection = { ...section, position: index + 1 };
    if (railsSection.isNew) {
      delete railsSection.isNew;
      delete railsSection.id;
    }
    if (railsSection.image) {
      railsSection.linked_image_id = railsSection.image.id;
      delete railsSection.image;
    } else {
      railsSection.linked_image_id = null;
      railsSection.image = null;
    }
    if (railsSection.inlineImage) {
      railsSection.linked_inline_image_id = railsSection.inlineImage.id;
      railsSection.inline_image_title = railsSection.inlineImage.title;
      delete railsSection.inlineImage;
    } else {
      railsSection.linked_inline_image_id = null;
      railsSection.inline_image = null;
    }
    if (railsSection.download) {
      railsSection.linked_file_id = railsSection.download.id;
      railsSection.file_title = railsSection.download.title;
      delete railsSection.download;
    } else {
      railsSection.linked_file_id = null;
      railsSection.file_title = null;
      railsSection.file = null;
    }
    if (railsSection.sponsorTierId) {
      railsSection.sponsorship_sponsor_tier_id = railsSection.sponsorTierId;
    }
    delete railsSection.sponsorTierId;
    if (railsSection.trackIds) {
      railsSection.track_ids = railsSection.trackIds;
    }
    delete railsSection.trackIds;
    return railsSection;
  });
  if (deletedSections) {
    deletedSections.forEach((id) => railsSections.push({ id, _destroy: true }));
  }

  return railsSections;
};

const mapPagesToRailsParams = (pages, deletedPages) => {
  const railsPages = pages.map((page, index) => {
    const railsPage = {
      ...page,
      track_ids: page.trackIds,
      position: index + 1,
    };
    if (railsPage.sections || railsPage.deletedSections) {
      railsPage.microsite_sections_attributes = mapSectionsToRailsParams(
        railsPage.sections || [],
        railsPage.deletedSections
      );
    }
    delete railsPage.trackIds;
    delete railsPage.sections;
    delete railsPage.deletedSections;
    if (railsPage.isNew) {
      delete railsPage.isNew;
      delete railsPage.id;
    }
    return railsPage;
  });
  if (deletedPages) {
    deletedPages.forEach((id) => railsPages.push({ id, _destroy: true }));
  }
  return railsPages;
};

export default function MicrositeEditor({
  pages,
  eventPaths = undefined,
  tracks = undefined,
  apiBaseUrl,
  saveUrl,
  fileUploadUrl,
  sponsorshipEnabled,
  richTextEnabled = false,
  markdownSectionsEnabled = false,
}) {
  const [loading, setLoading] = useState(false);
  const [initialPages, setPages] = useState(pages);
  const apiClient = new AdminApiClient(apiBaseUrl, railsFetch);
  const handleSave = async (newPages, deletedPages) => {
    const mappedPages = mapPagesToRailsParams(newPages, deletedPages);
    setLoading(true);
    const response = await railsFetch(saveUrl, {
      method: "PUT",
      body: JSON.stringify({
        event: {
          microsite_pages_attributes: mappedPages,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    setPages(data.pages);
  };

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append("file_upload[file]", file, file.name);
    const response = await railsFetch(fileUploadUrl, {
      method: "POST",
      body: formData,
    });
    return await response.json();
  };

  return (
    <ErrorHandler>
      <ChakraProvider theme={gregAdminTheme}>
        <AdminApiContext.Provider value={apiClient}>
          <QueryClientProvider client={new QueryClient()} contextSharing={true}>
            <BaseEditor
              initialPages={initialPages}
              eventPaths={eventPaths}
              tracks={tracks}
              onSave={handleSave}
              onFileUpload={handleFileUpload}
              sponsorshipEnabled={sponsorshipEnabled}
              useRichText={richTextEnabled}
              markdownSectionsEnabled={markdownSectionsEnabled}
            />
          </QueryClientProvider>
        </AdminApiContext.Provider>
      </ChakraProvider>
    </ErrorHandler>
  );
}
