import { Controller } from "@hotwired/stimulus";

export class RefundTypeToggleController extends Controller {
  static targets = ["refundTypeSelect", "amount", "percentage"];

  connect() {
    this.setVisibility();
  }

  setVisibility() {
    if (this.refundTypeSelectTarget.value === "percentage") {
      this.amountTargets.forEach(
        (el) => (el.closest(".FormControl").hidden = true)
      );
      this.percentageTargets.forEach(
        (el) => (el.closest(".FormControl").hidden = false)
      );
    } else {
      this.amountTargets.forEach(
        (el) => (el.closest(".FormControl").hidden = false)
      );
      this.percentageTargets.forEach(
        (el) => (el.closest(".FormControl").hidden = true)
      );
    }
  }
}
